import React, { Fragment , useEffect, useReducer, useState } from 'react';
import ReportHeader from './ReportHeader';
import {RECEIVED_BASE_REPORT, SUPPLY_RECEIVED_BASE_REPORT} from '../../../../api/userUrl'
import { userGetMethod } from '../../../../api/userAction';
import './style.scss';
import { columnHead, rowHead } from '../../../admin/common/utils';

const Report = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [datas, setDatas] = useState([]);
    const [dates, setDates] = useState([]);
    
    const tableStyle = {
        "margin" : "2% 1% 2% 0%"
    }
    const styleRow = {
        fontSize: "15px",
        fontWeight: "bold"
    }
    const styleColumn = {
        fontSize: "12px",
        fontWeight: "bold"
    }
    const fromDate = props.match.params.fromDate;
    const toDate = props.match.params.toDate;
    const supplierId = props.match.params.supplierId;
    const oneDay = 24 * 60 * 60 * 1000;
    useEffect(()=>{
        userGetMethod(`${SUPPLY_RECEIVED_BASE_REPORT}?fromDate=${fromDate}&&toDate=${toDate}&&supplierId=${supplierId}`) 
        .then(response => {
            console.log('response', response.data);
            setDatas(response.data.supplierBaseOrderRecives);
            // setDates(response.data.dates);
            setIsLoading(false);
        })
        .catch(error => console.log(error))
    }, []);
    
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className=""> 
                            <ReportHeader />

                            <div className="mainDiv" style={{"padding": "1% 5%"}}>
                                        <div className="text-center">
                                            <h3>{'Supplier Base Recive Report - '+fromDate+' to '+toDate}</h3>
                                        </div>
                            {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                                (
                                    <>
                                        <Fragment>
                                        <div className="row">
                                            {datas.length > 0 ? (
                                                Object.entries(
                                                    datas.reduce((acc, item) => {
                                                        const supplierName = item?.base_order_details?.supplier?.name || 'Unknown Supplier';
                                                        acc[supplierName] = acc[supplierName] || [];
                                                        acc[supplierName].push(item);
                                                        return acc;
                                                    }, {})
                                                ).map(([supplierName, items]) => {
                                                    const totalQyt = items.reduce((total, item) => {
                                                        const itemQty = item.items?.reduce((subTotal, subItem) => subTotal + (subItem.total_order_qty || 0), 0) || 0;
                                                        return total + itemQty;
                                                    }, 0);
                                                    return (
                                                        <>
                                                            <div>
                                                            <h4>Supplier Name: {supplierName}</h4>
                                                            </div>
                                                            <table
                                                                className="particulars table table-bordered table-stripped reportBody"
                                                                cellSpacing="5"
                                                                cellPadding="5"
                                                                width="100%"
                                                                style={tableStyle}
                                                            >
                                                                <thead>
                                                                    <tr style={{ backgroundColor: "#d7d4d4" }}>
                                                                        <th style={styleRow} width="10%" align="center">Job No</th>
                                                                        <th style={styleRow} width="20%" align="center">Job Name</th>
                                                                        <th style={styleRow} width="10%" align="center">Receive Base Date</th>
                                                                        <th style={styleRow} width="10%" align="center">Face Length</th>
                                                                        <th style={styleRow} width="10%" align="center">Circumference</th>
                                                                        <th style={styleRow} width="10%" align="center">Total Surface Area</th>
                                                                        <th style={styleRow} width="10%" align="center">Total Order CTY</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {items.map((item) =>
                                                                    (
                                                                        <tr key={`${supplierName}`}>
                                                                            <td style={styleColumn}>{item.job_order.job_no}</td>
                                                                            <td style={styleColumn}>{item.job_order.job_name}</td>
                                                                            <td style={styleColumn}>{item.base_receive_date}</td>
                                                                            <td style={styleColumn}>{item.job_order.face_length}</td>
                                                                            <td style={styleColumn}>{item.job_order.circumference}</td>
                                                                            <td style={styleColumn}>
                                                                                {item.job_order.total_surface_area}
                                                                            </td>
                                                                            
                                                                            <td style={styleColumn}>{item.total_order_qty}</td>
                                                                        </tr>
                                                                    )
                                                                )}
                                                                    {/* <tr>
                                                                        <th
                                                                            style={{
                                                                                paddingLeft: "6px",
                                                                                fontSize: "14px",
                                                                                fontWeight: "bold",
                                                                                textAlign: "right",
                                                                            }}
                                                                            colSpan="6"
                                                                        >
                                                                            Total
                                                                        </th>
                                                                        <th
                                                                            style={{
                                                                                paddingLeft: "6px",
                                                                                fontSize: "14px",
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        >
                                                                            {totalQyt}
                                                                        </th>
                                                                    </tr> */}
                                                                </tbody>
                                                            </table>
                                                        
                                                        </>
                                                    );
                                                })
                                            ) : null}
                                        </div>    
                                        </Fragment>
                                    </>
                                )
                            } 
                            </div>  
                        </div>    
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default Report;