import React, { Fragment , useEffect, useReducer, useState } from 'react';
import './style.scss';
import PendingPlatingScheduleHeader from './PendingPlatingScheduleHeader';
import { PLATING_PENDING_SCHEDULE } from '../../../../api/userUrl';
import { userGetMethod } from '../../../../api/userAction';
import { columnHead, rowHead } from '../../../admin/common/utils';

const PendingPlatingSchedule = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [pendingPlatingSchedules, setPendingPlatingSchedules] = useState([]);
    
    const tableStyle = {
        "margin" : "2% 1% 2% 0%"
    }
    
    useEffect(()=>{
        userGetMethod(`${PLATING_PENDING_SCHEDULE}`) 
        .then(response => {
            console.log('response', response.data);
            setPendingPlatingSchedules(response.data.getAllPendingplatingList);
            setIsLoading(false);
        })
        .catch(error => console.log(error))
    }, []);
  return (
    <Fragment>
    <div className="container-fluid">
        <div className="row">
            <div className="col-sm-12">
                <div className=""> 
                    <PendingPlatingScheduleHeader pendingPlatingSchedules={pendingPlatingSchedules}/>
  
                    <div className="mainDiv" style={{"padding": "1% 5%"}}>
                    {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                        (
                            <>
                                <div className="text-center">
                                    <h5>Pending Plating Schedule</h5>
                                </div>
                                <Fragment>
                                    <div className="row">
                                        <table className="particulars table table-bordered table-stripped reportBody" cellSpacing="5" cellPadding="5" width="100%"  style={tableStyle}>
                                            <thead>    
                                            <tr>
                                            <th style={rowHead} scope="col" width="5%" >SL.</th>
                                                      {/* <th scope="col" width="5%" onClick={() => sortHandler(1)} ><i className="fa fa-sort"></i> SL.</th> */}
                                                    <th style={rowHead} scope="col" width="10%" > Job No.</th>
                                                    <th style={rowHead} scope="col" width="10%" > Agreement Date </th>
                                                    <th style={rowHead} scope="col" width="30%"> Job Name</th>
                                                    <th style={rowHead} scope="col" width="10%" > Type</th>                                                        
                                                    <th style={rowHead} scope="col" width="15%" > Cylinder Quantity</th>
                                            
                                            </tr>   
                                            </thead>
                                            <tbody>
                                                {pendingPlatingSchedules.length > 0 ?
                                                    pendingPlatingSchedules.map((pendingPlatingSchedule,index)=>(    
                                                        <tr style={columnHead} key={index}>
                                                                        <td scope="row">{ (index+1)  }</td>
                                                                        <td>{pendingPlatingSchedule.job_no}</td>
                                                                        <td>{pendingPlatingSchedule.agreement_date}</td>
                                                                        <td>{pendingPlatingSchedule.job_name}</td>
                                                                        {/* <td>{pendingPlatingSchedule.done_cyl_qty} : {pendingPlatingSchedule.total_cylinder_qty}</td> */}
                                                                        <td>{pendingPlatingSchedule.job_type}</td>
                                                                        <td>{pendingPlatingSchedule.done_cyl_qty} : {pendingPlatingSchedule.total_cylinder_qty}</td>
                                                            </tr>  
                                                    ))
                                                : null
                                                }
                                            </tbody>
                                        </table>
                                    </div>    
                                </Fragment>
                            </>
                        )
                    } 
                    </div>  
                </div>    
            </div>
        </div>
    </div>
  </Fragment>
  )
}

export default PendingPlatingSchedule






