import React, { Fragment , useEffect, useReducer, useState } from 'react';
import './style.scss';
import { LAYOUT_PENDING_SCHEDULE } from '../../../../api/userUrl';
import { userGetMethod } from '../../../../api/userAction';
import PendingLayoutHeader from './PendingLayoutHeader';
import { columnHead, rowHead } from '../../../admin/common/utils';

const PendingLayout = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [pendingLayoutSchedules, setPendingLayoutSchedules] = useState([]);
    
    const tableStyle = {
        "margin" : "2% 1% 2% 0%"
    }
    
    useEffect(()=>{
        userGetMethod(`${LAYOUT_PENDING_SCHEDULE}`) 
        .then(response => {
            console.log('response', response.data);
            setPendingLayoutSchedules(response.data.getPendingLayoutList);
            setIsLoading(false);
        })
        .catch(error => console.log(error))
    }, []);
  return (
    <Fragment>
    <div className="container-fluid">
        <div className="row">
            <div className="col-sm-12">
                <div className=""> 
                    <PendingLayoutHeader pendingLayoutSchedules={pendingLayoutSchedules}/>
  
                    <div className="mainDiv" style={{"padding": "1% 5%"}}>
                    {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                        (
                            <>
                                <div className="text-center">
                                    <h5>Pending Layout Schedule</h5>
                                </div>
                                <Fragment>
                                    <div className="row">
                                        <table className="particulars table table-bordered table-stripped reportBody" cellSpacing="5" cellPadding="5" width="100%"  style={tableStyle}>
                                            <thead>    
                                            <tr style={rowHead}>
                                            <th scope="col" width="5%" >SL.</th>     
                                                <th scope="col" width="5%"> Job No.</th>
                                                <th scope="col" width="20%"> Job Name</th>
                                                <th scope="col" width="5%"> Type</th>
                                                <th scope="col" width="15%"> Client</th>
                                            
                                            </tr>   
                                            </thead>
                                            <tbody>
                                                {pendingLayoutSchedules.length > 0 ?
                                                    pendingLayoutSchedules.map((pendingLayoutSchedule,index)=>(    
                                                        <tr style={columnHead} key={index}>
                                                                    <td scope="row">{ (index+1)  }</td>
                                                                    <td>{pendingLayoutSchedule.job_no}</td>
                                                                    <td>{pendingLayoutSchedule.job_name}</td>
                                                                    <td>{pendingLayoutSchedule.job_type}</td>
                                                                    <td>{pendingLayoutSchedule.client_name}</td>
                                                            </tr>  
                                                    ))
                                                : null
                                                }
                                            </tbody>
                                        </table>
                                    </div>    
                                </Fragment>
                            </>
                        )
                    } 
                    </div>  
                </div>    
            </div>
        </div>
    </div>
  </Fragment>
  )
}

export default PendingLayout









