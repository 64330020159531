import React, { useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { CircleLoader } from 'react-spinners';
import { GET_DUE_CLINENTS_DETAILS } from "../../../api/userUrl";
import { userGetMethod } from "../../../api/userAction";

function dashboardModal({showModal, duesClients, closeModal,dueClientLoading,cliendDuesList, setEmployeesData, employeesData,setEmployeeLoading,employeeLoading}) {
    const onClient = async (employee_id) => {
        try {
            setEmployeeLoading(true);
            userGetMethod(`${GET_DUE_CLINENTS_DETAILS}?employee_id=${employee_id}`)
            .then(response => {
                setEmployeesData(response.data);
                setEmployeeLoading(false);
            })
        } catch (error) {
          console.error('Error fetching client data:', error);
          setEmployeeLoading(false);
        }
      };
  return (
    <div>
        <Modal show={showModal} onHide={closeModal} centered className="custom-modal">
         {dueClientLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '600px' }}>
            <div><CircleLoader  
                    color="#0da7e0"
                    height={4}
                    speedMultiplier={1}/>
            </div>
            <p style={{ fontSize: '20px', color: '#0da7e0', marginLeft: '10px'}}>Loading.....</p>
          </div>
        ) 
         : 
         (
         <div>
          <Modal.Header closeButton>
            <Modal.Title>Dues Clients</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="row">
          <div className="col-md-4">
            <div style={{ maxHeight: '550px', overflowY: 'auto' }}>
                <Table striped bordered hover>
                        <thead className="text-center">
                        <tr>
                            <th>SL</th>
                            <th>S.A(lac)</th>
                            <th>Qty</th>
                        </tr>
                        </thead>
                        <tbody className="text-center">
                        {cliendDuesList.length > 0 ?
                        <>
                            {cliendDuesList.map((item, index) =>           
                                    (
                                        <tr onClick={() => onClient(item.employee_id)} style={{ cursor: 'pointer' }}>
                                            <td>{item.employee_id}</td>
                                            <td style={{ textAlign: 'left' }}>{item.employee_name}</td>
                                            <td>{item.total_due_clients}</td>
                                        </tr> 
                                    )                
                                )}
                            
                        </>  
                            
                            : 
                            <tr>
                                <td colSpan="9" className="text-center">No data found</td>
                            </tr>}
                        </tbody>
                </Table>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
                <div className="col-md-12 col-lg-12">
                    <div className="input-group text-box searchBox" style={{ marginTop: '0px' }}>
                        <input
                            type="text"
                            className="form-control input-txt-bx col-md-6"
                            placeholder="Type to Search..."
                            // onChange={(e) => handleSearchText(e.target.value)}
                            // onKeyDown={handleKeyDown}
                            style={{ marginTop: '0px',marginLeft:'0px' }}
                            />
                            <div className="input-group-append" style={{ marginTop: '0px' }}>
                                <button className="btn btn-primary btn-sm" type="button" 
                                // onClick={searchHandler}
                                >Go
                                </button>
                            </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 col-lg-12">
                    {employeeLoading ? 
                    (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '550px' }}>
                        <div><CircleLoader  
                                color="#0da7e0"
                                height={4}
                                speedMultiplier={1}/>
                        </div>
                        <p style={{ fontSize: '20px', color: '#0da7e0', marginLeft: '10px'}}>Loading.....</p>
                    </div>
                    )
                    :
                    (
                    <div style={{ maxHeight: '550px', overflowY: 'auto' }}>
                        <Table striped bordered hover>
                                <thead className="text-center">
                                <tr>
                                <th>Client id</th>
                                <th>Client Name</th>
                                <th>Balance</th>
                                </tr>
                                </thead>
                                <tbody className="text-center">
                                {employeesData.length > 0 ?
                                <>
                                    {employeesData.map((item, index) =>           
                                    (
                                        <tr style={{ cursor: 'pointer' }}>
                                            <td>{item.client_id}</td>
                                            <td style={{ textAlign: 'left' }}>{item.client_name}</td>
                                            <td id='right-align-with-space'>{item.balance}</td>
                                        </tr> 
                                    )                
                                )}
                                    
                                </>  
                                    
                                    : 
                                    <tr>
                                        <td colSpan="9" className="text-center">No data found</td>
                                    </tr>}
                                </tbody>
                        </Table>
                    </div>
                    )
                }
                </div>
            </div>
          </div>
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
          </div>
        )}
        </Modal>
        <style>
            {`
            #right-align-with-space {
                text-align: right !important;
                padding-right: 20px !important;
            }
            .custom-modal .modal-dialog {
                max-width: 80% !important;
                margin: auto !important; 
            }
            `}
        </style>
    </div>
    
  )
}

export default dashboardModal