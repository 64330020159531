import React, { Fragment , useEffect, useReducer, useState } from 'react';
import './style.scss';
import { ENGRAVING_SCHEDULE_PENDING} from '../../../../api/userUrl';
import { userGetMethod } from '../../../../api/userAction';
import EngravingScheduleHeader from './EngravingScheduleHeader';
import { columnHead, rowHead } from '../../../admin/common/utils';

const EngravingSchedule = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [engravingSchedules, setEngravingSchedules] = useState([]);
    
    const tableStyle = {
        "margin" : "2% 1% 2% 0%"
    }
    
    useEffect(()=>{
        userGetMethod(`${ENGRAVING_SCHEDULE_PENDING}`) 
        .then(response => {
            console.log('response', response.data);
            setEngravingSchedules(response.data.allPendingEngravingList);
            setIsLoading(false);
        })
        .catch(error => console.log(error))
    }, []);
    
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className=""> 
                            <EngravingScheduleHeader engravingSchedules={engravingSchedules}/>

                            <div className="mainDiv" style={{"padding": "1% 5%"}}>
                            {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                                (
                                    <>
                                        <div className="text-center">
                                            <h5>Pending Engraving Schedule</h5>
                                        </div>
                                        <Fragment>
                                            <div className="row">
                                                <table className="particulars table table-bordered table-stripped reportBody" cellSpacing="5" cellPadding="5" width="100%"  style={tableStyle}>
                                                    <thead>    
                                                    <tr style={rowHead}>
                                                    <th scope="col" width="5%" > SL.</th>
                                                    <th scope="col" width="10%"> Cyl Id.</th>
                                                    <th scope="col" width="15%"> Job Name</th>
                                                    {/* <th scope="col" width="15%" onClick={() => sortHandler(3)} > Job Name</th> */}
                                                    <th scope="col" width="5%"> FL</th>
                                                    <th scope="col" width="5%"> Cir</th>
                                                    <th scope="col" width="5%"> Dia</th>
                                                    <th scope="col" width="15%"> Printer Name</th>
                                                    <th scope="col" width="10%"> Plating Order</th>
                                                    <th scope="col" width="10%"> FL</th>
                                                    <th scope="col" width="15%"> Client</th>
                                                    
                                                    </tr>   
                                                    </thead>
                                                    <tbody>
                                                        {engravingSchedules.length > 0 ?
                                                            engravingSchedules.map((engravingSchedule,index)=>(    
                                                                <tr style={columnHead} key={index}>
                                                                        <td scope="row">{ (index+1)  }</td>
                                                                        <td>{engravingSchedule.cylinder_id}</td>
                                                                        <td>{engravingSchedule.job_name}</td>
                                                                        <td>{engravingSchedule.fl}</td>
                                                                        <td>{engravingSchedule.cir}</td>
                                                                        <td>{engravingSchedule.dia}</td>
                                                                        <td>{engravingSchedule.printer_name}</td>
                                                                        <td>{engravingSchedule.plating_order}</td>
                                                                        <td>{engravingSchedule.before_fl}</td>
                                                                        <td>{engravingSchedule.client_name}</td>
                                                                    </tr>  
                                                            ))
                                                        : null
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>    
                                        </Fragment>
                                    </>
                                )
                            } 
                            </div>  
                        </div>    
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default EngravingSchedule;
