import React, { Fragment , useEffect, useState } from 'react';
import useForm from "react-hook-form";
import { SubmitButton } from '../../../common/GlobalButton'
import {PENDING_BASE_FORM, RECEIVED_BASE_FORM} from '../../../../api/userUrl'
import { userGetMethod} from '../../../../api/userAction';
import { Typeahead } from 'react-bootstrap-typeahead';

const Form = (props) => {
    const { handleSubmit, register, errors } = useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [typeheadOptions, setTypeheadOptions] = useState({});
    const [dropdownData, setDropdownData] = useState({});

    var menuId = 0;
    if (props.location.state === undefined) {
        menuId = 0;
    }else{
        menuId = props.location.state.params.menuId;
    }

    useEffect(() => {
        userGetMethod(`${RECEIVED_BASE_FORM}`)
        .then(response => {
            if (response.data.clients) {
                let clientOptions = [];
                if (response.data.clients && response.data.clients.length > 0) {
                response.data.clients.map(client => {
                    let clientObj = {};
                    let name = client.name;
                    clientObj.id = client.id;
                    clientObj.name = `[${client.client_id}] ${name.slice(0,30)}`;
                    clientOptions.push(clientObj);
                })
                }
                setTypeheadOptions(
                    (prevstate) => ({
                      ...prevstate,
                      ['clients']: clientOptions,
                    })
                  );
            }
            // setClients(response.data.clients);
            setIsLoading(false);
        })
        .catch(error => console.log(error))
    }, []);
    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const dropDownChange = (event, stateName) => {
        if (event.length > 0) {
            const selectedValue = event[0].id;
            // console.log(selectedValue);
            // setDropDownText(stateName)
            setDropdownData(
              (prevstate) => ({
                ...prevstate,
                [stateName]: selectedValue,
              })
            );
          }
    }
    const submitHandler = (data, e) => {
        // const client_id = data.client_id;
        const client_id = dropdownData.client_id;
        const from_date = data.from_date;
        console.log('from_date ', data);
        let url = `${process.env.PUBLIC_URL}/pendingBase/${data.from_date}/${data.to_date}/${client_id ? client_id : null}`;
        window.open(url, '_blank', 'height=800,width=1200');
    }
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5>Pending Base</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit(submitHandler)} className="needs-validation theme-form">
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="from_date">Date Range</label>
                                        <div className="col-sm-4">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <input 
                                                                className="form-control"
                                                                id="from_date" 
                                                                name="from_date" 
                                                                type="date" defaultValue={getTodayDate()}
                                                                ref={register({
                                                                    required: 'From Date Field Required'
                                                                })}
                                                            />
                                                            {errors.from_date && <p className='text-danger'>{errors.from_date.message}</p>}
                                                        </td>
                                                        <td> <span style={{'padding': '5px'}}> - </span> </td>
                                                        <td>
                                                            <input 
                                                                className="form-control"
                                                                id="to_date" 
                                                                name="to_date" 
                                                                type="date" defaultValue={getTodayDate()}
                                                                ref={register({
                                                                    required: 'To Date Field Required'
                                                                })}
                                                            />
                                                            {errors.to_date && <p className='text-danger'>{errors.to_date.message}</p>}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="client_id">Select Client</label>
                                        <div className="col-sm-3">
                                            <Typeahead
                                                id="client_id"
                                                name="client_id"
                                                labelKey={(option) => `${option.name}`}
                                                options={typeheadOptions["clients"] || []}
                                                placeholder="Select Client..."
                                                onChange={(e) => dropDownChange(e, "client_id")}
                                                // inputProps={{ required: true }}
                                                // selected={typeAheadValue["client_id"]}
                                                // ref={register({
                                                //   required: "Client Name Field Required",
                                                // })}
                                                {...register("client_id")}
                                            />
                                            {errors.client_id && <p className='text-danger'>{errors.client_id.message}</p>}

                                        </div>
                                    </div>
                                    <SubmitButton link="#" offset="2" menuId={ menuId }/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
export default Form;